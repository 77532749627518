import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../components/HomeScreen.css";
import "./Footer.css"
interface ImageProps {
    imageUrl: any;
}
const NavHeader: FunctionComponent<ImageProps> = ({ imageUrl }) => {

    const navigate = useNavigate();

    function productsPage() {
        // navigate('/product-main');
        window.location.href = 'https://www.spaarksweb.com/';
    }

    function homePage() {
        navigate('/');
    }

    function teamPage() {
        navigate('/team');
    }

    function careerPage() {
        // navigate('/career');
        window.location.href = 'https://ososweb.zohorecruit.in/jobs/Careers';
    }

    function spaarksWebPage() {
        window.open('https://www.spaarksweb.com/');
    }
    const logoImg = "/logo-1@2x.png"
    const roketBg = "/assets/images/products/roketBlue.svg"
    const hamburger = "/assets/images/menu.svg"
    const [isMobile, setIsMobile] = useState<boolean>(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [sidemenu, setSideMenu] = useState<boolean>(false)
    const hideHamburgerMenu = () => {
        setSideMenu(!sidemenu)
    }
    const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            // Clicking on the overlay will close the sidebar
            // dispatch(closeSidebar());
            setSideMenu(false)
        }
    }

    return (
        <>

            <div className="d-flex shadow header">
                <div className="col-12 d-flex">
                    <div className="col-6">
                        <img src={logoImg} alt="" className="logoImg" onClick={homePage} />
                    </div>
                    {!isMobile &&
                        <div className="col-6">
                            <div className="header-right">
                                <div className="pointer  ms-4 mb-3 " onClick={homePage}>Home</div>
                                <div className="pointer  ms-4 mb-3 " onClick={productsPage}>Product</div>
                                <div className="pointer  ms-4 mb-3" onClick={teamPage}>Team</div>
                                <div className="pointer  ms-4 mb-3 me-5" onClick={careerPage}>Career</div>
                                <img src={imageUrl} alt="" onClick={spaarksWebPage} className="me-3 roketImg" />
                            </div>

                        </div>
                    }
                    {
                        isMobile &&
                        <div className="col-6 z-3 sticky-top ">
                            <div className="header-right">
                                <div className="pointer  ms-4 mb-3 " onClick={hideHamburgerMenu}><img src={hamburger} alt="" className="hamburger " /></div>
                                {

                                    <div className={`mobile-sidebar ${sidemenu ? "open" : ""}`}>
                                        <button className="toggle-button" onClick={hideHamburgerMenu}>
                                            <i className="fas fa-bars"></i>
                                        </button>
                                        <div className="sidebar-content">
                                            <ul>
                                                <div className="pointer  ms-4 mb-3 " onClick={homePage}>Home</div>
                                                <div className="pointer  ms-4 mb-3 " onClick={productsPage}>Product</div>
                                                <div className="pointer  ms-4 mb-3" onClick={teamPage}>Team</div>
                                                <div className="pointer  ms-4 mb-3 me-5" onClick={careerPage}>Career</div>
                                                <img src={imageUrl} alt="" onClick={spaarksWebPage} className="me-3 roketImg" />
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>


                    }
                    {/* Overlay to blur the background and disable click */}
                    {sidemenu && (
                        <div className="sidebar-overlay" onClick={handleOverlayClick}></div>
                    )}
                </div>

            </div>
        </>


    );
};

export default NavHeader;




